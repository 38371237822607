import { pick, shake } from 'radash'

import type { Notification } from '~~/server/api/notifications/types'
import type { Subscription } from '~~/server/api/subscriptions/types'

import { debug } from '~/utils/logging'

const removeUnneededProps = (updates: Notification[]) => {
  return updates.map((item) =>
    shake(pick(item, ['uuid', 'seenAt', 'dismissedAt'])),
  )
}

export const useNotifications = () => {
  const { locale } = useI18n()
  const { data: session } = useAuth()
  const _headers = computed(() => session.value?.headers)

  const state = reactive<Map<string, Notification>>(new Map())
  const updates = reactive<Set<string>>(new Set())

  const fetch = async () => {
    const lang = unref(locale)
    const headers = unref(_headers)

    if (!(session.value || updates.size)) return null

    const body = updates.size
      ? removeUnneededProps(
          [...updates.values()].map((uuid) =>
            state.get(uuid),
          ) as Notification[],
        )
      : undefined

    try {
      const response = await $fetch('/api/notifications', {
        method: (body ? 'POST' : 'GET') as 'POST' | 'GET',
        headers,
        query: { lang },
        body,
      })

      for (const item of response.data) {
        state.set(item.uuid, item)
      }
      updates.clear()

      return response
    } catch (err) {
      console.error('Error fetching notifications', err)
    }

    return []
  }

  const { pending, execute } = useAsyncData<{ data: Notification[] | null }>(
    'notifications',
    fetch,
    {
      server: false,
      immediate: false,
    },
  )

  const throttle = 1000 * 5 // every 5sec
  const update = useThrottleFn(execute, throttle)
  const { pause, resume, isActive } = useIntervalFn(update, 1000 * 60 * 15) // every 15 min

  // @todo: pause/resume fetch based on user interaction

  const data = computed(() => [...state.values()])

  const dismissed = computed(() =>
    [...state.values()].filter(
      ({ dismissedAt }: Notification) => !!dismissedAt,
    ),
  )

  const mark = (
    notification: Notification | string,
    mark: 'seenAt' | 'dismissedAt',
  ): boolean => {
    const uuid =
      typeof notification === 'string' ? notification : notification.uuid

    if (!state.has(uuid)) return false

    const rec = state.get(uuid) as Notification
    rec[mark] = new Date()
    updates.add(uuid)

    update()
    return true
  }

  const markSeen = (notification: Notification | string): boolean =>
    mark(notification, 'seenAt')

  const markDismissed = (notification: Notification | string): boolean =>
    mark(notification, 'dismissedAt')

  onMounted(() => {
    debug('[notifications] onMount')
    execute()
  })

  return {
    state,
    data,
    dismissed,
    pending,
    isActive,
    execute,
    seen: markSeen,
    dismiss: markDismissed,
  }
}

export const useSubscriptions = () => {
  const { locale } = useI18n()
  const { data: session } = useAuth()
  const _headers = computed(() => session.value?.headers)

  const state = reactive<Map<string, Subscription>>(new Map())
  const updates = reactive<Set<string>>(new Set())

  const fetch = async () => {
    const lang = unref(locale)
    const headers = unref(_headers)

    const body = updates.size
      ? [...updates.values()].map((uuid) => state.get(uuid))
      : undefined

    try {
      const response = await $fetch('/api/subscriptions', {
        method: (body ? 'POST' : 'GET') as 'POST' | 'GET',
        headers,
        query: { lang },
        body,
      })

      info(
        `[app:subscriptions] ${
          body ? 'Updating' : 'Fetching'
        } notification subscriptions`,
      )

      for (const item of response) {
        state.set(item.subscription, item)
      }
      updates.clear()

      return response
    } catch (err) {
      console.error('Error fetching subscriptions', err)
    }
  }

  const {
    data: response,
    pending,
    execute,
  } = useAsyncData('subscriptions', fetch, {
    server: false,
    immediate: false,
  })

  const data = computed(() => [...state.values()])

  useIntervalFn(() => {
    if (!updates.size) return
    execute()
  }, 5000)

  const get = (channel: string): Subscription | undefined => state.get(channel)

  const subscribe = async (channel: string): Promise<boolean> => {
    const subscription = get(channel)
    if (!subscription) return false
    if (subscription.isActive) return true

    subscription.isActive = true
    updates.add(channel)
    return true
  }

  const unsubscribe = async (channel: string): Promise<boolean> => {
    const subscription = get(channel)
    if (!subscription) return false
    if (!subscription.isActive) return true

    subscription.isActive = false
    updates.add(channel)
    return true
  }

  onMounted(() => execute())

  return {
    state,
    data,
    pending,
    get,
    execute,
    subscribe,
    unsubscribe,
  }
}
